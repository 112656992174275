<template>
  <v-app>
    <v-app-bar
        app
        color="primary accent-4"
        dense
        dark
    >
      <v-toolbar-title>
        <v-icon>mdi-door-closed-lock</v-icon>
        Door Opener
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <DoorOpener/>
    </v-main>
  </v-app>
</template>

<script>
import DoorOpener from './components/DoorOpener';

export default {
  name: 'App',

  components: {
    DoorOpener,
  },

  data: () => ({
    //
  }),
};
</script>
