<template>
  <v-container fluid>
    <v-row align="center"
           justify="center">
      <v-col
          col="12"
          align="center"
      >

        <v-btn
            x-large
            color="blue-grey"
            class="ma-2 white--text"
            :loading="waitingState"
            @click="openDoor()"
        >
          <v-icon
              x-large
              dark
          >
            mdi-lock-open-variant-outline
          </v-icon>
        </v-btn>


      </v-col>
    </v-row>

    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
    >
      <v-card>
        <v-toolbar
            dark
            color="secondary"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Challenge</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container class="pa-12">
            <v-row>
              <v-col
                  v-for="(iconId, id) in challenge"
                  :key="id"
                  cols="3"
                  align="center"
              >
                <v-btn
                    :disabled="buttonIsSelected(id)"
                    @click="buttonSelect(id)"
                >
                  <v-icon>{{ icons[iconId] }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              :loading="waitingState"
              @click="sendChallenge()"
          >
            <v-icon>mdi-lock-question</v-icon>
            ENTER
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="problem"
        timeout="5000"
    >
      {{ problemText }}
    </v-snackbar>
  </v-container>
</template>

<script>
const baseURIRequireChallenge = '/api/door/requireChallenge'
const baseURIChallenge = '/api/door/challenge'
const baseURIOpen = '/api/door/open'
const baseURIOpenChallenge = '/api/door/open/challenge'

export default {
  name: 'DoorBell',

  data: () => ({
    icons: [
      'mdi-bag-checked',
      'mdi-star',
      'mdi-food-apple',
      'mdi-bag-carry-on',
      'mdi-food-croissant',
      'mdi-blender',
      'mdi-clover',
      'mdi-clipboard-account',
      'mdi-airplane',
      'mdi-space-invaders',
      'mdi-access-point',
      'mdi-arrow-top-right-bold-box',
      'mdi-battery',
      'mdi-car',
      'mdi-beaker',
      'mdi-chart-bar',
      'mdi-food-turkey',
      'mdi-pot-steam',
      'mdi-bugle',
      'mdi-hamburger',
      'mdi-bowl-mix',
      'mdi-cash',
    ],
    waitingState: true,
    requireChallenge: false,
    dialog: false,
    problem: false,
    problemText: "N/A",
    challenge: [],
    selected: [],
    challengeId: "",
  }),

  mounted: function() {
    // check if we need a challenge
    this.axios.get(baseURIRequireChallenge).then((response) =>  {
      this.requireChallenge = response.data
    }).finally(() => {
      this.waitingState = false
    })
  },

  methods: {
    openDoor: function() {
      if (this.requireChallenge) {
        this.showChallenge()
      } else {
        this.sendOpenDoor()
      }
    },

    sendOpenDoor: function() {
      this.waitingState = true
      this.axios.post(baseURIOpen).finally(() => {
        this.waitingState = false
      })
    },

    showChallenge: function () {
      this.selected = []

      this.waitingState = true
      this.axios.post(baseURIChallenge).then((response) => {
        this.challengeId = response.data.challengeId
        this.challenge = response.data.challenge
        this.dialog = true
      }).finally(() => {
        this.waitingState = false
      })

    },
    buttonIsSelected: function (id) {
      return this.selected.includes(id)
    },
    buttonSelect: function (id) {
      if (!this.buttonIsSelected(id)) {
        this.selected.push(id)
      }
    },
    sendChallenge: function () {
      this.waitingState = true
      this.dialog = false

      this.axios.post(baseURIOpenChallenge, {
        challengeId: this.challengeId,
        solution: this.selected
      }).then(() => {
        console.log("OK")
      }).catch((error) => {
        this.problemText = "Challenge failed"
        this.problem = true
        console.log(error);
      }).finally(() => {
        this.waitingState = false
      })
    }
  }
}
</script>
